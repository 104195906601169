import { FC } from 'react'

import { Providers } from './providers'
import { AppRouter } from './routers'

interface IApp {}

export const App: FC<IApp> = () => {
	return (
		<Providers>
			<AppRouter />
		</Providers>
	)
}
