import { FC } from 'react'

import { ReactComponent as FilterSvg } from 'shared/assets/icons/filter.svg'

interface IFilterIcon {
	readonly className?: string
}

export const FilterIcon: FC<IFilterIcon> = ({ className }) => {
	return <FilterSvg className={className} />
}
