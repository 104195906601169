import { FC } from 'react'

import { ReactComponent as ParkingSvg } from 'shared/assets/icons/parking.svg'

interface IParkingIcon {
	readonly className?: string
}

export const ParkingIcon: FC<IParkingIcon> = ({ className }) => {
	return <ParkingSvg className={className} />
}
