import React, { useEffect, useRef, useState } from 'react'

interface SmoothNumberProps {
	value: number // Текущее число, которое нужно плавно менять
	month: string
	duration?: number
}

export const SmoothNumber: React.FC<SmoothNumberProps> = ({
	value,
	duration = 400,
	month,
}) => {
	const [displayValue, setDisplayValue] = useState(value) // Отображаемое число
	const startValueRef = useRef(value) // Начальное значение
	const animationFrameRef = useRef<number | null>(null) // Ссылка на requestAnimationFrame
	const startTimeRef = useRef<number | null>(null) // Время начала анимации

	useEffect(() => {
		const startValue = startValueRef.current // Начальное значение
		const delta = value - startValue // Разница между текущим и новым значением

		// Если разницы нет, анимация не нужна
		if (delta === 0) return

		const animate = (timestamp: number) => {
			if (startTimeRef.current === null) {
				startTimeRef.current = timestamp // Установка времени начала
			}

			const elapsed = timestamp - startTimeRef.current // Время, прошедшее с начала
			const progress = Math.min(elapsed / duration, 1) // Прогресс от 0 до 1
			const interpolatedValue = startValue + delta * progress // Интерполяция

			setDisplayValue(interpolatedValue) // Установка нового отображаемого значения

			if (progress < 1) {
				animationFrameRef.current = requestAnimationFrame(animate) // Продолжение
			} else {
				// Завершение анимации
				startValueRef.current = value
				startTimeRef.current = null
				animationFrameRef.current = null
			}
		}

		animationFrameRef.current = requestAnimationFrame(animate)

		// Очистка при размонтировании
		return () => {
			if (animationFrameRef.current) {
				cancelAnimationFrame(animationFrameRef.current)
			}
		}
	}, [value, duration])

	return <span>{`${displayValue.toFixed(0)} ${month}`}</span> // Округление для отображения
}
