import { FC } from 'react'

import { ReactComponent as DottedClockSvg } from 'shared/assets/icons/dottedClock.svg'

interface IDottedClockIcon {
	readonly className?: string
}

export const DottedClockIcon: FC<IDottedClockIcon> = ({ className }) => {
	return <DottedClockSvg className={className} />
}
