import { useMutation } from '@tanstack/react-query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { FC, useState } from 'react'

import { ProfileCard } from 'entities/profileCard'
import { Progress } from 'entities/progress'

import { freezeSubscribe, unFreezeSubscribe } from 'shared/api/product'
import { BigFreezeIcon, GroupIcon, SubscribeIcon } from 'shared/icons'
import { handleErrorStatus, toastError } from 'shared/lib/handleError'
import { formatDate } from 'shared/lib/utils'
import { ISubscribe } from 'shared/types'
import { Button } from 'shared/ui/button'
import { IconTitle } from 'shared/ui/iconTitle'

import './profileSubscribe.scss'

interface IProfileSubscribe {
	readonly isDefault: boolean
	readonly subscribe: ISubscribe
}

export const ProfileSubscribe: FC<IProfileSubscribe> = ({
	isDefault,
	subscribe,
}) => {
	const {
		balance,
		freeze,
		product,
		date_end,
		is_activated,
		id,
		balance_original,
		date_start,
		types,
	} = subscribe
	const [isFreezeSubscribe, setIsFreezeSubscribe] = useState(freeze)
	const [isAnimationGone, setIsAnimationGone] = useState(false)
	const startAnimation = () => {
		setIsAnimationGone(true)
		setTimeout(() => {
			setIsAnimationGone(false)
		}, 1500)
	}
	const { mutate: freezeMutate, isPending: isFreezeLoading } = useMutation({
		mutationKey: ['freezeSubscribe'],
		mutationFn: (id: number) => freezeSubscribe(id),
		onSuccess: () => {
			setIsFreezeSubscribe(true)
			startAnimation()
		},
		onError: (e) => {
			if (handleErrorStatus(e) === 403) {
				toastError(e, 'Заморозка невозможна')
			}
		},
	})
	const { mutate: unFreezeMutate, isPending: isUnFreezeLoading } = useMutation({
		mutationKey: ['unFreezeSubscribe'],
		mutationFn: (id: number) => unFreezeSubscribe(id),
		onSuccess: () => setIsFreezeSubscribe(false),
	})
	return (
		<article className="profile-subscribe">
			<ProfileCard
				className={clsx(
					'profile-subscribe__wrapper',
					isDefault && !isFreezeSubscribe && 'active'
				)}
			>
				<div className="profile-subscribe__header">
					<p className="green-highlight uppercase bold">{product}</p>
					<IconTitle
						Icon={GroupIcon}
						title={types[0]}
						className="profile-subscribe__header__type"
					/>
				</div>
				<SubscribeIcon
					className={clsx('profile-subscribe__icon', isDefault && 'active')}
				/>
				<div className="profile-subscribe__info">
					<p>Куплен</p>
					<p>{dayjs(date_start).format('DD.MM.YYYY')}</p>
				</div>
				<div className="profile-subscribe__info">
					<p>Истекает</p>
					<p className={clsx(is_activated && 'green-highlight')}>
						{date_end ? formatDate(date_end) : '∞'}
					</p>
				</div>
				<Progress
					title="Осталось тренировок"
					maxValue={balance_original}
					value={balance}
				/>
				{isFreezeSubscribe && (
					<div className="profile-subscribe__freeze">
						<BigFreezeIcon />
					</div>
				)}
				<div className="profile-subscribe__actions fade-in">
					{!isFreezeSubscribe ? (
						<>
							<Button disabled={isFreezeLoading} rounded theme="black">
								Выбрать основным
							</Button>
							<Button
								isLoading={isFreezeLoading}
								onClick={() => freezeMutate(id)}
								rounded
								theme="black"
							>
								Заморозить
							</Button>
						</>
					) : (
						<Button
							isLoading={isUnFreezeLoading}
							onClick={() => unFreezeMutate(id)}
							rounded
							theme="black"
							className={clsx(
								'profile-subscribe__actions__unfreeze',
								isAnimationGone && 'animation'
							)}
						>
							<div className={'profile-subscribe__actions__unfreeze__content'}>
								{isAnimationGone ? (
									''
								) : (
									<div className="scale-in">Разморозить</div>
								)}
							</div>
						</Button>
					)}
				</div>
			</ProfileCard>
			{isDefault && !isFreezeSubscribe && (
				<span className="profile-subscribe__tip">
					Используется для записи на тренировки по умолчанию
				</span>
			)}
			{isFreezeSubscribe && (
				<span className="profile-subscribe__tip">Абонемент заморожен</span>
			)}
		</article>
	)
}
