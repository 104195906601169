import { FC } from 'react'

import { ReactComponent as VkSvg } from 'shared/assets/icons/vk.svg'

interface IVkIcon {
	readonly className?: string
}

export const VkIcon: FC<IVkIcon> = ({ className }) => {
	return <VkSvg className={className} />
}
