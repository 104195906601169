import clsx from 'clsx'
import { FC } from 'react'

import { ChangeIcon, TrainerIcon } from 'shared/icons'

import './trainer.scss'

interface ITrainer {
	readonly first_name: string
	readonly last_name: string
	readonly isChanged: boolean
	readonly className?: string
}

export const Trainer: FC<ITrainer> = ({
	first_name,
	last_name,
	className,
	isChanged,
}) => {
	return (
		<div
			className={clsx('class-trainer', isChanged && 'red-highlight', className)}
		>
			{isChanged ? <ChangeIcon className={'red-highlight'} /> : <TrainerIcon />}
			<span className="ellipsis">
				{last_name} {first_name}
			</span>
		</div>
	)
}
