// Context
import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import DropDownIcon from 'components/IconComponents/DropDownIcon'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { PopupAccount } from 'widgets/PopupAccount'

import { ToggleTheme } from 'features/toggleTheme'

import { getUserProfileData } from 'shared/api/account'
import { HEADER_LINKS } from 'shared/constants'
import {
	BellIcon,
	DottedClockIcon,
	LogoutIcon,
	PersonIcon,
	SunIcon,
	TelegramIcon,
	WarningIcon,
} from 'shared/icons'
import { toastError } from 'shared/lib/handleError'
import { useResize } from 'shared/lib/useResize/useResize'
import { pluralWorkouts } from 'shared/lib/utils'
import { Theme, useSessionStore, useThemeContext } from 'shared/model'
import { CountTip } from 'shared/ui/countTip'
import { HamburgerButton } from 'shared/ui/hamburgerButton'
import { IconTitle } from 'shared/ui/iconTitle'
import { Logo } from 'shared/ui/logo'

import './Header.scss'

const Header = () => {
	const { session, isPopupAccountOpen, setIsPopupAccountOpen } =
		useSessionStore(
			({ session, setIsPopupAccountOpen, isPopupAccountOpen }) => ({
				session,
				setIsPopupAccountOpen,
				isPopupAccountOpen,
			})
		)
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const {
		data: profile,
		isLoading: isLoadingProfile,
		isSuccess: isSuccessProfile,
		error: errorProfile,
	} = useQuery({
		queryKey: ['userProfile', session?.id],
		queryFn: () => getUserProfileData(),
		staleTime: 60000 * 30,
		enabled: !!session?.id,
	})
	useEffect(() => {
		if (errorProfile) {
			toast.error('Не удалось загрузить данные о активных абонементах')
		}
	}, [errorProfile])
	const navRef = useRef<HTMLElement>(null)
	const triggerRef = useRef<HTMLButtonElement>(null)
	const renderNearWorkout = (date: string | null) => {
		if (!date) return 'Ближайших нет'
		const dateWorkout = dayjs(date)
		return dateWorkout.format('dd, DD MMM в HH:mm')
	}
	const navigate = useNavigate()
	// useEffect(() => {
	// 	const handleClickOutside = (event: MouseEvent) => {
	// 		if (
	// 			navRef.current &&
	// 			!navRef.current.contains(event.target as Node) &&
	// 			triggerRef.current &&
	// 			!triggerRef.current.contains(event.target as Node) &&
	// 			isMenuOpen
	// 		) {
	// 			setIsMenuOpen(false)
	// 		}
	// 	}
	// 	document.addEventListener('click', handleClickOutside)
	// 	return () => {
	// 		document.removeEventListener('click', handleClickOutside)
	// 	}
	// }, [navRef, isMenuOpen, setIsMenuOpen, triggerRef])

	/*** Handlers ***/
	const handleLogin = () => {
		session ? navigate('/profile/about') : setIsPopupAccountOpen(true)
	}
	// попап выхода из аккаунта
	const handleLogout = () => {
		setIsPopupAccountOpen(true)
	}
	const { pathname } = useLocation()
	useEffect(() => {
		setIsMenuOpen(false)
	}, [pathname])
	const { MD_SCREEN, XXL_SCREEN, SM_SCREEN, LG_SCREEN } = useResize()
	const { theme } = useThemeContext(({ theme }) => ({
		theme,
	}))
	const isDarkMode = theme === Theme.DARK
	return (
		<>
			<header className="header" id="header">
				<div className="header__content">
					{/*** Logo ***/}
					<Logo className="header__logo" />
					{/*** Menu ***/}
					{!LG_SCREEN && (
						<nav className="header__nav">
							<ul className="header__list">
								<li
									className={clsx(
										'header__item header__item__dropdown',
										(pathname === '/training/' || pathname === '/gym/') &&
											'active'
									)}
								>
									<span className="header__item__dropdown__title">
										Тренировки
										<DropDownIcon className="header__item__dropdown__icon" />
									</span>
									<ul className="header__item__dropdown__list">
										<li className="header__item__dropdown__list__item">
											<Link to={'/training/'}>Виды тренировок</Link>
										</li>
										<li className="header__item__dropdown__list__item">
											<Link to={'/gym/'}>Залы</Link>
										</li>
									</ul>
								</li>
								<li
									className={clsx(
										'header__item',
										pathname === '/schedule/' && 'active'
									)}
								>
									<Link to={'/schedule/'}>Расписание</Link>
								</li>
								<li
									className={clsx(
										'header__item',
										pathname === '/payment/' && 'active'
									)}
								>
									<Link to={'/payment/'}>Цены</Link>
								</li>
							</ul>
						</nav>
					)}

					<div className="header__actions">
						{!SM_SCREEN && <ToggleTheme className="header__actions__icon" />}
						{isSuccessProfile && !XXL_SCREEN && (
							<>
								<div className="header__actions__userInfo near-class">
									{renderNearWorkout(
										profile.near_klass && profile.near_klass.date
									)}
								</div>
								<div className="header__actions__userInfo">
									{profile?.balance.length > 0 ? profile.balance[0].balance : 0}
								</div>
							</>
						)}
						{!MD_SCREEN && (
							<Link
								className="header__actions__icon"
								to="https://t.me/volleybox_official"
								target="_blank"
							>
								<TelegramIcon />
							</Link>
						)}
						<button className="header__actions__icon bell">
							<BellIcon />
							<CountTip count={1} />
						</button>
						{!session ? (
							<button
								className="header__actions__icon"
								onClick={() => handleLogin()}
							>
								<PersonIcon />
							</button>
						) : (
							<Link to="/profile/about" className="header__actions__icon">
								<PersonIcon />
							</Link>
						)}
						{/*** Logout ***/}
						{session && (
							<button
								className="header__actions__icon logout"
								onClick={() => handleLogout()}
							>
								<LogoutIcon />
							</button>
						)}

						{/*** Burger ***/}
						{LG_SCREEN && (
							<HamburgerButton
								className=" header__actions__icon burger"
								ref={triggerRef}
								onClick={() => setIsMenuOpen(!isMenuOpen)}
								active={isMenuOpen}
							/>
						)}
					</div>
				</div>
			</header>
			{LG_SCREEN && (
				<>
					<nav
						ref={navRef}
						className={clsx(
							'header__nav-mob',
							isMenuOpen && 'header__nav-mob--open'
						)}
					>
						<ul className="header__nav-mob__list">
							{HEADER_LINKS.map(({ path, title }) => (
								<li
									key={path}
									onClick={() => setIsMenuOpen(false)}
									className="header__nav-mob__item fade-in"
								>
									<Link
										className={clsx(
											'header__nav-mob__link',
											pathname === path && 'active'
										)}
										to={path}
									>
										{title}
									</Link>
								</li>
							))}
						</ul>
						{isSuccessProfile && (
							<div className="header__nav-mob__eventsInfo">
								<div className="header__nav-mob__item">
									<IconTitle
										Icon={DottedClockIcon}
										title={renderNearWorkout(
											profile.near_klass && profile.near_klass.date
										)}
									/>
									<p className="header__nav-mob__eventsInfo__tip">
										Ваша ближайшая тренировка
									</p>
								</div>
								<div className="header__nav-mob__item">
									<IconTitle
										Icon={WarningIcon}
										title={
											profile.balance.length > 0
												? pluralWorkouts(profile.balance[0].balance)
												: 'Нет активных абонементов'
										}
									/>
									<p className="header__nav-mob__eventsInfo__tip">
										{profile.balance.length > 0
											? `$Осталось в абонементе${
													profile.balance[0].balance
														? ' до ' +
														  dayjs(profile.balance[0].date_end).format('D MMM')
														: ''
											  }`
											: 'Пора приобрести новый абонемент'}
									</p>
								</div>
							</div>
						)}
						{SM_SCREEN && (
							<ToggleTheme className="header__nav-mob__item header__nav-mob__theme">
								<span>{isDarkMode ? 'Темная тема' : 'Светлая тема'}</span>
							</ToggleTheme>
						)}
					</nav>
					<div
						onClick={() => setIsMenuOpen(false)}
						className={clsx('header__nav-mob__overlay', isMenuOpen && 'active')}
					/>
				</>
			)}
			{/*** Account popup ***/}
			{isPopupAccountOpen && <PopupAccount />}
		</>
	)
}

export default Header
