import clsx from 'clsx'
import { ButtonHTMLAttributes, CSSProperties, FC } from 'react'

import { CheckIcon } from 'shared/icons'

import './toggleCheckbox.scss'

interface IToggleCheckbox extends ButtonHTMLAttributes<HTMLButtonElement> {
	readonly checked: boolean
	readonly accentColor?: string
	readonly Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
	readonly avatar?: string
}

export const ToggleCheckbox: FC<IToggleCheckbox> = ({
	checked,
	onClick,
	className,
	Icon,
	children,
	accentColor,
	avatar,
}) => {
	return (
		<button
			className={clsx('toggle-checkbox', checked && 'active', className)}
			onClick={onClick}
			style={
				accentColor ? ({ '--accentColor': accentColor } as CSSProperties) : {}
			}
		>
			<div className="toggle-checkbox__content">
				{Icon && (
					<Icon
						className={clsx('toggle-checkbox__icon', checked && 'active')}
					/>
				)}
				{avatar && (
					<img
						src={avatar}
						alt="Аватар тренера"
						className={clsx('toggle-checkbox__avatar', checked && 'active')}
					/>
				)}
				<span className="ellipsis">{children}</span>
			</div>
			<CheckIcon
				className={clsx(
					'toggle-checkbox__icon',
					'toggle-checkbox__icon--check',
					checked && 'checked'
				)}
			/>
		</button>
	)
}
