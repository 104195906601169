import { FC } from 'react'
import { Link } from 'react-router-dom'

import logo from 'shared/assets/icons/logo.svg'

interface ILogo {
	readonly className?: string
}

export const Logo: FC<ILogo> = ({ className }) => {
	return (
		<Link to="/" className={className}>
			<img src={logo} alt="Логотип" />
		</Link>
	)
}
