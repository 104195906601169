import { FC } from 'react'

import { ReactComponent as WarningSvg } from 'shared/assets/icons/warning.svg'

interface IWarningIcon {
	readonly className?: string
}

export const WarningIcon: FC<IWarningIcon> = ({ className }) => {
	return <WarningSvg className={className} />
}
