import clsx from 'clsx'
import { FC } from 'react'

import defaultAvt from 'shared/assets/default-photo-avatar.webp'
import { sortPlayers } from 'shared/lib/utils'
import { useSessionStore } from 'shared/model'
import { IClassById, IPlayer } from 'shared/types'
import { PersonInfo } from 'shared/ui/personInfo'
import { ProgressBar } from 'shared/ui/progressBar'

import './workoutSquad.scss'

interface IWorkoutSquad {
	readonly workout: IClassById
}

export const WorkoutSquad: FC<IWorkoutSquad> = ({ workout }) => {
	const players = [
		...workout.players,
		{ first_name: 'Тест', last_name: 'Тестович', id: 9997 },
		{ first_name: 'Тест', last_name: 'Тестович', id: 9998 },
		{ first_name: 'Тест', last_name: 'Тестович', id: 9996 },
		{ first_name: 'Тест', last_name: 'Тестович', id: 9995 },
		{ first_name: 'Тест', last_name: 'Тестович', id: 9994 },
	] as IPlayer[]
	console.log(workout.players)
	const session = useSessionStore((state) => state.session)
	const squad = session ? sortPlayers(session.id, players) : players
	return (
		<article className="workout-squad">
			<div className="workout-squad__progress">
				<ProgressBar current={players.length} max={workout.limit} height={40} />
			</div>
			<div className="workout-squad__players">
				{squad.map((player) => (
					<PersonInfo
						avatar={defaultAvt}
						name={
							`${player.role ? player.role + ': ' : ''}` +
							player.first_name +
							' ' +
							player.last_name
						}
						key={player.id}
						className={clsx(player.id === session?.id && 'current-player')}
					/>
				))}
			</div>
		</article>
	)
}
