import { FC } from 'react'

import { ReactComponent as MoonSvg } from 'shared/assets/icons/moon.svg'

interface IMoonIcon {
	readonly className?: string
	readonly id?: string
}

export const MoonIcon: FC<IMoonIcon> = ({ className, id }) => {
	return <MoonSvg id={id} className={className} />
}
