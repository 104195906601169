import { FC } from 'react'

import { ReactComponent as ArrowSvg } from 'shared/assets/icons/arrow.svg'

interface IArrow {
	readonly className?: string
	readonly isLeft?: boolean
}

export const Arrow: FC<IArrow> = ({ className, isLeft }) => {
	const direction = isLeft ? { transform: 'rotate(180deg)' } : {}
	return <ArrowSvg style={direction} className={className} />
}
