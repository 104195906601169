import { FC } from 'react'

import { ReactComponent as CloseSvg } from 'shared/assets/icons/close.svg'

interface ICloseIcon {
	readonly className?: string
}

export const CloseIcon: FC<ICloseIcon> = ({ className }) => {
	return <CloseSvg className={className} />
}
