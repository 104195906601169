import { getAllProductsOptions } from './api/groupProducts'
import { ProductCard } from './productCard'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import { IGroupProduct } from 'shared/api/product/types'
import { useSessionStore } from 'shared/model'
import { Tab } from 'shared/ui/tab'

import './PaymentPage.scss'

const PaymentPage = () => {
	const session = useSessionStore((state) => state.session)
	const [userId, setUserId] = useState<number | 'guest'>('guest')
	const [activeTab, setActiveTab] = useState<IGroupProduct | null>(null)

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [activeTab])

	useEffect(() => {
		setUserId(session ? session.id : 'guest')
	}, [session])

	const { data: products, isSuccess } = useQuery(getAllProductsOptions(userId))

	useEffect(() => {
		if (isSuccess) {
			const defaultProduct =
				products.find((product) => product.is_published) || null
			setActiveTab(defaultProduct)
		}
	}, [isSuccess, products])

	const handleClick = (id: number) => {
		if (id === activeTab?.id) return
		setActiveTab(products?.find((product) => product.id === id) || null)
	}
	return (
		<div className="products">
			<article className="products__tabs">
				{isSuccess &&
					products.map(({ id, title }) => (
						<Tab
							active={activeTab?.id === id}
							onClick={() => handleClick(id)}
							key={id}
						>
							{title}
						</Tab>
					))}
			</article>
			<section className="products__content">
				{activeTab && (
					<ul className="products__list">
						{activeTab.products.map((product) => (
							<li key={product.id}>
								<ProductCard product={product} group={activeTab.title} />
							</li>
						))}
					</ul>
				)}
			</section>
		</div>
	)
}

export default PaymentPage
