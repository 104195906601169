import clsx from 'clsx'
import { FC } from 'react'

import { ReactComponent as LogoSvg } from 'shared/assets/icons/logo.svg'

interface ILogoVector {
	readonly className?: string
}

export const LogoVector: FC<ILogoVector> = ({ className }) => {
	return <LogoSvg className={clsx(className)} />
}
