import { FC } from 'react'

import { ReactComponent as TelegramFillSvg } from 'shared/assets/icons/telegramFill.svg'

interface ITelegramFillIcon {
	readonly className?: string
}

export const TelegramFillIcon: FC<ITelegramFillIcon> = ({ className }) => {
	return <TelegramFillSvg className={className} />
}
