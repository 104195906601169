import clsx from 'clsx'
import { ButtonHTMLAttributes, ForwardedRef, forwardRef } from 'react'

import './hamburgerButton.scss'

interface IHamburgerButton extends ButtonHTMLAttributes<HTMLButtonElement> {
	readonly active: boolean
}

const HamburgerButtonWrapper = (
	{ active, className, ...rest }: IHamburgerButton,
	ref: ForwardedRef<HTMLButtonElement>
) => {
	return (
		<button
			{...rest}
			ref={ref}
			aria-label={active ? 'Закрыть меню' : 'Открыть меню'}
			className={clsx('hamburger', active && 'active', className)}
		>
			<i>menu</i>
		</button>
	)
}
export const HamburgerButton = forwardRef(HamburgerButtonWrapper)
HamburgerButton.displayName = 'HamburgerButton'
