import { useClassesQuery } from './zScheduleCal/api/classes'
import {
	classLevelOptions,
	gymsOptions,
	trainersOptions,
} from './zScheduleCal/api/filter'
import { ScheduleFilter } from './zScheduleCal/scheduleFilter/scheduleFilter'
import { ScheduleHeader } from './zScheduleCal/scheduleHeader'
import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { ScheduleWeek } from 'widgets/scheduleWeek'

import { getSession } from 'shared/lib/auth'
import { toastError } from 'shared/lib/handleError'
import { useResize } from 'shared/lib/useResize'
import { useRange } from 'shared/lib/utils'
import { useScheduleStore } from 'shared/model'
import { PuffSpinner } from 'shared/ui/puffSpinner'

import './SchedulePage.scss'

dayjs.extend(updateLocale)

dayjs.updateLocale('ru', {
	monthsShort: [
		'янв',
		'фев',
		'мар',
		'апр',
		'мая',
		'июн',
		'июл',
		'авг',
		'сен',
		'окт',
		'ноя',
		'дек',
	],
})

const SchedulePage = () => {
	const [userId, setUserId] = useState<number | null>(null)
	const user = getSession()?.id
	useEffect(() => {
		setUserId(user ? user : null)
	}, [user])
	const {
		isOpenFilters,
		requestParams,
		setClasses,
		setIsOpenFilters,
		setSelectedDay,
		setCurrentStartRange,
		selectedDay,
	} = useScheduleStore(
		({
			requestParams,
			isOpenFilters,
			setClasses,
			setIsOpenFilters,
			setSelectedDay,
			setCurrentStartRange,
			selectedDay,
		}) => ({
			requestParams,
			isOpenFilters,
			setClasses,
			setIsOpenFilters,
			setSelectedDay,
			setCurrentStartRange,
			selectedDay,
		})
	)
	const {
		data: levels,
		isLoading: isLoadingLevels,
		error: errorLevels,
	} = useQuery(classLevelOptions)
	const {
		data: trainers,
		isLoading: isLoadingTrainers,
		error: errorTrainers,
	} = useQuery(trainersOptions)
	const {
		data: gyms,
		isLoading: isLoadingGyms,
		error: errorGyms,
	} = useQuery(gymsOptions)
	const { LG_SCREEN } = useResize()
	const isSuccessLoadingFilters = gyms && levels && trainers
	const {
		data: rangeClasses,
		isLoading: isLoadingClasses,
		isSuccess,
	} = useClassesQuery(requestParams, userId || 'guest')
	useEffect(() => {
		if (errorTrainers) toastError(errorTrainers, 'Ошибка загрузки тренеров')
		if (errorLevels) toastError(errorLevels, 'Ошибка загрузки уровней')
		if (errorGyms) toastError(errorGyms, 'Ошибка загрузки залов')
	}, [errorGyms, errorLevels, errorTrainers])

	useEffect(() => {
		if (isSuccess) {
			setClasses(rangeClasses)
		}
	}, [isSuccess, setClasses, rangeClasses])
	const { currentStartRange } = useRange()
	const stringRange = currentStartRange.toString()

	const [searchParams] = useSearchParams()
	const encryptedWorkout = searchParams.get('date')
	if (encryptedWorkout) {
		const decryptedDate = window.atob(encryptedWorkout)
		const date = dayjs(decryptedDate)
		if (!date.isoWeekday(1).isSame(currentStartRange, 'day')) {
			setCurrentStartRange(date.isoWeekday(1))
		}
		if (LG_SCREEN && !selectedDay.isSame(date, 'day')) {
			setSelectedDay(dayjs(date))
		}
	}

	const isLoading =
		isLoadingLevels || isLoadingTrainers || isLoadingGyms || isLoadingClasses
	useEffect(() => {
		window.scroll({
			behavior: 'smooth',
			top: 0,
			left: 0,
		})
	}, [stringRange, LG_SCREEN])
	const { MD_SCREEN } = useResize()
	return (
		<div className="schedule">
			<ScheduleHeader />
			<section
				id="workouts"
				className={clsx('schedule__content', isOpenFilters && 'filters-open')}
			>
				{isLoading && <PuffSpinner />}
				{!isLoading && (
					<>
						<div
							onClick={() => setIsOpenFilters(false)}
							className={clsx(
								'schedule__overlay',
								isOpenFilters && MD_SCREEN && 'filters-open'
							)}
						/>
						{isSuccessLoadingFilters && (
							<ScheduleFilter gyms={gyms} levels={levels} trainers={trainers} />
						)}
						<ScheduleWeek currentStartRange={currentStartRange} />
					</>
				)}
			</section>
		</div>
	)
}

export default SchedulePage
