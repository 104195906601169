import clsx from 'clsx'
import { FC } from 'react'

import { ClockIcon, TimeIcon } from 'shared/icons'
import { getClassTime } from 'shared/lib/getClassTime'

import './interval.scss'

interface IInterval {
	readonly start: string
	readonly duration: number
	readonly className?: string
}

export const Interval: FC<IInterval> = ({ start, duration, className }) => {
	const interval = getClassTime(start, duration)
	return (
		<div className={clsx('interval', className)}>
			<ClockIcon />
			<span>{interval}</span>
		</div>
	)
}
