import { FC } from 'react'

import { ReactComponent as TelegramSvg } from 'shared/assets/icons/telegram.svg'

interface ITelegramIcon {
	readonly className?: string
}

export const TelegramIcon: FC<ITelegramIcon> = ({ className }) => {
	return <TelegramSvg className={className} />
}
