import { FC } from 'react'

import { ReactComponent as PersonSvg } from 'shared/assets/icons/person.svg'

interface ITrainerIcon {
	readonly className?: string
}

export const TrainerIcon: FC<ITrainerIcon> = ({ className }) => {
	return <PersonSvg className={className} />
}
