import { FC } from 'react'

import { ReactComponent as LogoutSvg } from 'shared/assets/icons/logout.svg'

interface ILogoutIcon {
	readonly className?: string
}

export const LogoutIcon: FC<ILogoutIcon> = ({ className }) => {
	return <LogoutSvg className={className} />
}
