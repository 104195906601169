import clsx from 'clsx'
import { FC } from 'react'

import { FilterManage } from 'features/schedule/filterManage'

import { FilterIcon } from 'shared/icons'
import { useResize } from 'shared/lib/useResize'
import { useScheduleStore } from 'shared/model'
import { Button } from 'shared/ui/button'
import { CountTip } from 'shared/ui/countTip'

import CurrentRangeSwitcher from '../rangeSwitcher/RangeSwitcher'
import './scheduleHeader.scss'

interface IScheduleHeader {}
export const ScheduleHeader: FC<IScheduleHeader> = () => {
	const { isOpenFilters, setIsOpenFilters, filters } = useScheduleStore(
		({ isOpenFilters, setIsOpenFilters, filters }) => ({
			isOpenFilters,
			setIsOpenFilters,
			filters,
		})
	)
	const activeFiltersCount =
		filters.gym.length + filters.trainer.length + filters.classLevel.length
	const { MD_SCREEN } = useResize()
	return (
		<section className={'schedule-header'} id="schedule-header">
			{MD_SCREEN && (
				<article className="schedule-header__title">
					<h1>Расписание</h1>
					<Button
						onClick={(e) => {
							e.stopPropagation()
							setIsOpenFilters(!isOpenFilters)
						}}
						className={clsx(
							'schedule-header__trigger-filters',
							isOpenFilters && 'active'
						)}
						theme="transparent-green"
					>
						<FilterIcon />
						{activeFiltersCount > 0 && <CountTip count={activeFiltersCount} />}
					</Button>
				</article>
			)}
			<article className="schedule-header__bar">
				<CurrentRangeSwitcher />
				{!MD_SCREEN && <FilterManage />}
			</article>
		</section>
	)
}
