import clsx from 'clsx'
import { FC, useEffect, useRef } from 'react'

import { CloseIcon } from 'shared/icons'

import './modal.scss'

interface IModal {
	readonly children: React.ReactNode
	readonly onClose: () => void
	readonly closeButton?: boolean
	readonly className?: string
	readonly id?: string
	readonly small?: boolean
}

export const Modal: FC<IModal> = ({
	children,
	onClose,
	closeButton = false,
	className,
	id,
	small,
}) => {
	const modalRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		document.body.style.overflow = 'hidden'
		modalRef.current?.focus()
		return () => {
			document.body.style.overflow = ''
		}
	}, [])
	return (
		<div
			id={id}
			ref={modalRef}
			onClick={onClose}
			className={clsx('modal-overlay fade-in')}
			role="dialog"
		>
			<div className={clsx('modal-wrapper', className, small && 'small')}>
				<div onClick={(e) => e.stopPropagation()} className="modal-content">
					{children}
					{closeButton && (
						<button
							onClick={onClose}
							aria-label="Закрыть окно"
							className="modal-wrapper__close-btn"
						>
							<CloseIcon />
						</button>
					)}
				</div>
			</div>
		</div>
	)
}
