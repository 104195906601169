import { FC } from 'react'

import { Carousel } from 'widgets/carousel'

import defaultAvt from 'shared/assets/default-photo-avatar.webp'
import {
	BiometryIcon,
	DropIcon,
	GroupNewIcon,
	HighBowlIcon,
	LocationIcon,
	ParkingIcon,
	PhoneIcon,
	TelegramFillIcon,
} from 'shared/icons'
import { WhatsAppIcon } from 'shared/icons/whatsAppIcon'
import { useResize } from 'shared/lib/useResize'
import { IClassById } from 'shared/types'
import { IconTitle } from 'shared/ui/iconTitle'
import { LevelInfo } from 'shared/ui/level'

import './workoutFeatures.scss'

interface IWorkoutDetailFeatures {
	readonly workout: IClassById
}

export const WorkoutDetailFeatures: FC<IWorkoutDetailFeatures> = ({
	workout,
}) => {
	const TrainerAvatar = () => (
		<img src={defaultAvt} alt="Аватар тренера" draggable={false} />
	)
	const contacts = [
		{ title: '+7 (495) 000-00-00', Icon: PhoneIcon },
		{ title: '8 (800) 000-00-00', Icon: WhatsAppIcon },
		{ title: '@volleybox', Icon: TelegramFillIcon },
	]
	const { LG_SCREEN } = useResize()
	const about = [
		{ title: 'Проходная система', Icon: BiometryIcon },
		{ title: 'Есть душ', Icon: DropIcon },
		{ title: 'Есть кулер с водой', Icon: HighBowlIcon },
	]
	return (
		<article className="workout-detail">
			<div className="workout-detail__group">
				<p className="workout-detail__subgroup">Адрес</p>
				<IconTitle
					className="workout-detail__subgroup__item"
					Icon={LocationIcon}
					title={workout.gym.address}
				/>
			</div>
			<div className="workout-detail__group">
				<p className="workout-detail__subgroup">Тренер</p>
				<IconTitle
					className="workout-detail__subgroup__item"
					Icon={TrainerAvatar}
					title={`${workout.trainer.last_name} ${workout.trainer.first_name}`}
					isChanged={workout.is_changed_trainer}
				/>
			</div>
			<div className="workout-detail__group">
				<p className="workout-detail__subgroup">Парковка</p>
				<IconTitle
					Icon={ParkingIcon}
					title="Есть, платная"
					className="workout-detail__subgroup__item"
				/>
			</div>
			<div className="workout-detail__group">
				<p className="workout-detail__subgroup">Проходная система</p>
				{about.map(({ title, Icon }) => (
					<IconTitle
						key={title}
						Icon={Icon}
						title={title}
						className="workout-detail__subgroup__item"
					/>
				))}
			</div>
			<div className="workout-detail__group">
				<p className="workout-detail__subgroup">Контакты</p>
				{contacts.map(({ title, Icon }) => (
					<IconTitle
						className="workout-detail__subgroup__item"
						key={title}
						Icon={Icon}
						title={title}
					/>
				))}
			</div>
		</article>
	)
}
