import { FC } from 'react'

import { ReactComponent as YoutubeSvg } from 'shared/assets/icons/youtube.svg'

interface IYoutubeIcon {
	readonly className?: string
}

export const YoutubeIcon: FC<IYoutubeIcon> = ({ className }) => {
	return <YoutubeSvg className={className} />
}
