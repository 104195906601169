import { FC } from 'react'

import { ReactComponent as ChangeSvg } from 'shared/assets/icons/change.svg'

interface IChangeIcon {
	readonly className?: string
}

export const ChangeIcon: FC<IChangeIcon> = ({ className }) => {
	return <ChangeSvg className={className} />
}
