import { FC } from 'react'

import { ReactComponent as WhatsAppSvg } from 'shared/assets/icons/whatsapp.svg'

interface IWhatsAppIcon {
	readonly className?: string
}

export const WhatsAppIcon: FC<IWhatsAppIcon> = ({ className }) => {
	return <WhatsAppSvg className={className} />
}
