import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export enum Theme {
	LIGHT = 'light',
	DARK = 'dark',
}

export interface IThemeContext {
	theme: Theme
	setTheme: (theme: Theme) => void
}
const LOCAL_STORAGE_THEME_KEY = 'theme'
export const useThemeContext = create<IThemeContext>()(
	persist(
		(set) => ({
			theme: Theme.DARK,
			setTheme: (theme: Theme) => set({ theme }),
		}),
		{
			name: LOCAL_STORAGE_THEME_KEY,
		}
	)
)
