import clsx from 'clsx'
import { FC } from 'react'

import './personInfo.scss'

interface IPersonInfo {
	readonly avatar: string
	readonly name: string
	readonly className?: string
}

export const PersonInfo: FC<IPersonInfo> = ({ avatar, name, className }) => {
	return (
		<div className={clsx('person-info', className)}>
			<div className="person-info__content">
				<img src={avatar} alt="Аватар игрока" />
				<span className="ellipsis">{name}</span>
			</div>
		</div>
	)
}
