import Footer from 'components/Footer/Footer'
import Header from 'components/Header/Header'
import { FC } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Outlet } from 'react-router-dom'
import { Toaster } from 'sonner'

import './layout.scss'

interface ILayout {
	readonly children?: React.ReactNode
}

export const Layout: FC<ILayout> = ({ children }) => {
	return (
		<div className="layout">
			<SkeletonTheme
				baseColor="var(--skeleton-main)"
				highlightColor="var(--skeleton-highlight)"
			>
				<Header />

				<main className="main">{children ?? <Outlet />}</main>

				<Footer />
			</SkeletonTheme>
			<Toaster richColors position="bottom-center" visibleToasts={1} />
		</div>
	)
}
