import { IClass, IFilterType } from 'shared/types'

export const filterClasses = (classes: IClass[], filters: IFilterType) => {
	if (
		filters.trainer.length ||
		filters.gym.length ||
		filters.classLevel.length
	) {
		return classes
			.filter((classItem) => {
				if (filters.trainer.length === 0) return true
				return filters.trainer.includes(classItem.trainer.id)
			})
			.filter((classItem) => {
				if (filters.gym.length === 0) return true
				return filters.gym.includes(classItem.gym.id)
			})
			.filter((classItem) => {
				if (filters.classLevel.length === 0) return true
				return filters.classLevel.includes(classItem.level.id)
			})
	}
	return classes
}
