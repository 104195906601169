import { FC } from 'react'

import { ReactComponent as GroupNewSvg } from 'shared/assets/icons/groupNew.svg'

interface IGroupNewIcon {
	readonly className?: string
}

export const GroupNewIcon: FC<IGroupNewIcon> = ({ className }) => {
	return <GroupNewSvg className={className} />
}
