export const HEADER_LINKS = [
	{
		title: 'Расписание',
		path: '/schedule/',
	},
	{
		title: 'Цены',
		path: '/payment/',
	},
	{
		title: 'Виды тренировок',
		path: '/training/',
	},
	{
		title: 'Залы',
		path: '/gym/',
	},
]
