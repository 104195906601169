import clsx from 'clsx'
import { FC, InputHTMLAttributes } from 'react'
import { Control, Controller, ValidationRule } from 'react-hook-form'

import { Input } from 'shared/input'

import './field.scss'

interface IField extends InputHTMLAttributes<HTMLInputElement> {
	readonly control: Control<any>
	readonly label: string
	readonly error?: string | null
	readonly controlPattern?: ValidationRule<RegExp> | undefined
	readonly id: string
}

export const Field: FC<IField> = ({
	control,
	id,
	placeholder,
	required = false,
	label,
	type = 'text',
	error,
	className,
	controlPattern,
	...rest
}) => {
	return (
		<Controller
			name={id}
			control={control}
			rules={{ pattern: controlPattern }}
			render={({ field }) => (
				<div className={clsx('field-control', className)}>
					<label htmlFor={id}>
						{error ? <span className="red-highlight">{error}</span> : label}
						{required && !error && <span className="green-highlight">*</span>}
					</label>
					<Input
						id={id}
						type={type}
						placeholder={placeholder}
						required={required}
						aria-invalid={error ? 'true' : 'false'}
						{...rest}
						{...field}
					/>
				</div>
			)}
		/>
	)
}
