import { FC } from 'react'
import { Link } from 'react-router-dom'

import { ProfileCard } from 'entities/profileCard'

import { IUserData } from 'shared/api/account'
import { GreenArrow } from 'shared/icons'
import { formatDate } from 'shared/lib/utils'

import './profileWorkouts.scss'

interface IProfileWorkouts {
	readonly data: IUserData
}

export const ProfileWorkouts: FC<IProfileWorkouts> = ({ data }) => {
	const subscribe = data.balance[0]
	const subscribeDuration = subscribe?.date_end
		? formatDate(subscribe.date_end)
		: '∞'
	const nearClass = data.near_klass ? formatDate(data.near_klass.date) : '-'
	return (
		<article className="profile-workouts">
			<ProfileCard>
				<div className="profile-workouts__header">
					<h3 className="profile-workouts__header__title">Тренировки</h3>
					<Link
						target="_blank"
						to={'/profile/workouts'}
						className="profile-workouts__header__link link-scale"
					>
						к тренировкам
						<GreenArrow />
					</Link>
				</div>
				<ul className="profile-workouts__content">
					<li className="profile-workouts__content__item">
						<span>Ближайшая</span>
						<span>{nearClass}</span>
					</li>
					<li className="profile-workouts__content__item">
						<span>Абонемент</span>
						<span>{subscribe ? subscribe.product : '-'}</span>
					</li>
					<li className="profile-workouts__content__item">
						<span>Срок действия</span>
						<span>{subscribe ? subscribeDuration : '-'}</span>
					</li>
				</ul>
			</ProfileCard>
		</article>
	)
}
