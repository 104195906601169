import clsx from 'clsx'
import { FC } from 'react'

import './countTip.scss'

interface ICountTip {
	readonly count: number
	readonly position?: 'bottom' | 'top'
}

export const CountTip: FC<ICountTip> = ({ count, position = 'bottom' }) => {
	return (
		<span className={clsx('count-tip', `count-tip_position_${position}`)}>
			{count}
		</span>
	)
}
