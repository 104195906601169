import clsx from 'clsx'
import { FC } from 'react'

import './bigProgressBar.scss'

interface IBigProgressBar {
	readonly maxValue: number
	readonly currentValue: number
}

export const BigProgressBar: FC<IBigProgressBar> = ({
	currentValue,
	maxValue,
}) => {
	return (
		<div className="big-progress-bar">
			{new Array(maxValue).fill(null).map((_, idx) => (
				<div
					className={clsx(
						'big-progress-bar__item',
						currentValue > idx && 'active'
					)}
					key={idx}
				/>
			))}
		</div>
	)
}
