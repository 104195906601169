import { FC } from 'react'

import { ReactComponent as BiometrySvg } from 'shared/assets/icons/biometry.svg'

interface IBiometryIcon {
	readonly className?: string
}

export const BiometryIcon: FC<IBiometryIcon> = ({ className }) => {
	return <BiometrySvg className={className} />
}
