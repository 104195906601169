import { FC } from 'react'

import { ReactComponent as CheckSvg } from 'shared/assets/icons/check.svg'

interface ICheckIcon {
	readonly className?: string
}

export const CheckIcon: FC<ICheckIcon> = ({ className }) => {
	return <CheckSvg className={className} />
}
