import { FC } from 'react'

import { ReactComponent as LevelSvg } from 'shared/assets/icons/level.svg'

interface ILevelIcon {
	readonly className?: string
}

export const LevelIcon: FC<ILevelIcon> = ({ className }) => {
	return <LevelSvg className={className} />
}
