export const pluralWorkouts = (count: number) => {
	const ruCardinalRules = new Intl.PluralRules('ru-RU', { type: 'cardinal' })
	const suffixes = new Map([
		['one', 'занятие'],
		['few', 'занятия'],
		['many', 'занятий'],
	])
	const rule = ruCardinalRules.select(count)
	return `${count} ${suffixes.get(rule)}`
}
