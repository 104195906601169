import { useQuery } from '@tanstack/react-query'
import { FC, MouseEvent, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { PuffLoader } from 'react-spinners'
import { toast } from 'sonner'

import { ProfileCard } from 'entities/profileCard'
import { ProfileHistoryItem } from 'entities/profileHistoryItem'

import { IUserData, getHistory } from 'shared/api/account'
import { ArrowBlackBg, GreenArrow } from 'shared/icons'
import { useResize } from 'shared/lib/useResize/useResize'
import { useSessionStore } from 'shared/model'
import { Button } from 'shared/ui/button'
import { Modal } from 'shared/ui/modal'
import { Rating } from 'shared/ui/rating'
import { ToggleButton } from 'shared/ui/toggleButton'

import './profileHistory.scss'

interface IProfileHistory {}

export const ProfileHistory: FC<IProfileHistory> = () => {
	const { handleOpenNavigation, userProfile } = useOutletContext() as {
		handleOpenNavigation: () => void
		userProfile: IUserData
	}
	const [rate, setRate] = useState(0)
	const [viewMode, setViewMode] = useState<'history' | 'future'>('future')
	const { isXLargeScreen } = useResize()
	const handleChangeMode = (e: MouseEvent<HTMLButtonElement>) => {
		const mode = e.currentTarget.dataset.mode as 'history' | 'future'
		if (mode !== viewMode) {
			setViewMode(mode)
		}
	}
	const session = useSessionStore((state) => state.session)
	const { data: history, isLoading: isHistoryLoading } = useQuery({
		queryKey: ['history', session ? session.id : ''],
		queryFn: getHistory,
	})
	const [isRateModalOpen, setIsRateModalOpen] = useState(false)
	const handleRate = () => {
		setIsRateModalOpen(true)
	}
	const handleClose = () => {
		setIsRateModalOpen(false)
		setRate(0)
	}
	const future_trainings =
		userProfile.near_trainings.length > 0 ? userProfile.near_trainings : null

	return (
		<section className="profile-history">
			<div className="profile-history__header profile__sticky">
				<div className="profile__header-title">
					{!isXLargeScreen && (
						<button type="button" onClick={handleOpenNavigation}>
							<ArrowBlackBg />
						</button>
					)}
					<h1>История тренировок</h1>
				</div>
				<div className="profile-history__header__buttons">
					<ToggleButton
						data-mode="history"
						onClick={handleChangeMode}
						disabled={isHistoryLoading}
						active={viewMode === 'history'}
						className="profile-history__header__buttons__btn"
					>
						Прошедшие
					</ToggleButton>
					<ToggleButton
						data-mode="future"
						onClick={handleChangeMode}
						active={viewMode === 'future'}
						disabled={isHistoryLoading}
						className="profile-history__header__buttons__btn"
					>
						Предстоящие
					</ToggleButton>
				</div>
			</div>
			<article className="profile-history__content">
				{isHistoryLoading && (
					<div className="absolute-center">
						<PuffLoader size={120} color="var(--green)" />
					</div>
				)}
				{history && !isHistoryLoading && viewMode === 'history' && (
					<ProfileCard className="profile-history__card">
						<div className="profile-history__list__wrapper">
							<ul className="profile-history__list">
								{history.map(({ date, id, gym, duration }) => (
									<ProfileHistoryItem
										key={id}
										id={id}
										type={viewMode}
										date={date}
										metro={gym.title}
										duration={duration}
										handleRate={handleRate}
									/>
								))}
							</ul>
						</div>
					</ProfileCard>
				)}
				{viewMode === 'future' &&
					!isHistoryLoading &&
					(future_trainings ? (
						<ProfileCard className="profile-history__card">
							<ul className="profile-history__list">
								{future_trainings.map((future_workout) => (
									<ProfileHistoryItem
										id={future_workout.id}
										date={future_workout.date}
										type={viewMode}
										metro={future_workout.gym}
										duration={120}
									/>
								))}
							</ul>
						</ProfileCard>
					) : (
						<div className="profile-history__text">
							<p>У вас отсутствуют предстоящие тренировки</p>
							<Link
								to="/schedule"
								className="green-highlight profile-history__text__link link-scale"
							>
								Посмотреть расписание
								<GreenArrow />
							</Link>
						</div>
					))}
			</article>
			{isRateModalOpen && (
				<Modal closeButton small className="rate-modal" onClose={handleClose}>
					<h3 className="rate-modal__title">Оценить тренировку</h3>
					<Rating rate={rate} setRate={setRate} />
					<Button
						onClick={() => {
							toast.success(`Ваша оценка: ${rate}`)
							handleClose()
						}}
						theme="green"
					>
						Подтвердить
					</Button>
				</Modal>
			)}
		</section>
	)
}
