import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { filterClasses, getStartEndRange } from 'shared/lib/utils'
import { IClass, IFilterType } from 'shared/types'

dayjs.locale('ru')

interface IScheduleStore {
	scheduleView: 'week' | 'month'
	currentStartRange: dayjs.Dayjs
	requestParams: string
	selectedDay: dayjs.Dayjs
	classes: IClass[]
	initialClasses: IClass[]
	filters: IFilterType
	isOpenFilters: boolean
	setIsOpenFilters: (isOpen: boolean) => void
	setFilters: (filters: IFilterType) => void
	setClasses: (classes: IClass[]) => void
	setSelectedDay: (date: dayjs.Dayjs) => void
	setRequestParams: (requestParams: string) => void
	setCurrentStartRange: (date: dayjs.Dayjs) => void
	setScheduleView: (scheduleView: 'week' | 'month') => void
}

export const useScheduleStore = create<IScheduleStore>()(
	persist(
		(set, get) => {
			return {
				classes: [],
				initialClasses: [],
				scheduleView: 'week',
				currentStartRange: dayjs().isoWeekday(1),
				requestParams: getStartEndRange().strParam,
				selectedDay: dayjs(),
				filters: {
					trainer: [],
					gym: [],
					classLevel: [],
				},
				isOpenFilters: false,
				setIsOpenFilters: (isOpen) => set({ isOpenFilters: isOpen }),
				setClasses: (classes) => {
					const filteredClasses = filterClasses(classes, get().filters)
					set({ classes: filteredClasses })
					set({ initialClasses: classes })
				},
				setFilters: (filters) => {
					const filteredClasses = filterClasses(get().initialClasses, filters)
					set({ classes: filteredClasses })
					set({ filters })
				},
				setSelectedDay: (date) => set({ selectedDay: date }),
				setRequestParams: (requestParams) => {
					set({ requestParams })
				},
				setCurrentStartRange: (date) => {
					const { strParam } = getStartEndRange(date, get().scheduleView)
					set({ requestParams: strParam })
					const preparedDate = date.hour(3)
					set({ currentStartRange: preparedDate })
				},
				setScheduleView: (scheduleView) => {
					const { strParam } = getStartEndRange(
						get().currentStartRange,
						scheduleView
					)
					set({ requestParams: strParam })
					set({ scheduleView })
				},
			}
		},
		{
			name: 'volleybox-storage',
			partialize: (state) => ({
				filters: state.filters,
				requestParams: state.requestParams,
				currentStartRange: state.currentStartRange,
			}),
		}
	)
)
