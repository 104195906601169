import { WorkoutDetailFeatures } from './workoutDetailFeatures/workoutFeatures'
import { WorkoutPhoto } from './workoutDetailPhoto/workoutPhoto'
import { WorkoutSquad } from './workoutSquad/workoutSquad'
import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { FC, TouchEvent, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import { getClassById } from 'shared/api/class'
import { CheckIcon, ShareIcon } from 'shared/icons'
import { useResize } from 'shared/lib/useResize'
import { Interval } from 'shared/ui/interval'
import { LevelInfo } from 'shared/ui/level'
import { Tab } from 'shared/ui/tab'

import './workoutDetailModal.scss'

interface IWorkoutDetailModal {
	readonly renderButton: () => React.ReactNode
	readonly classId: number
	readonly date: string
	readonly handleCloseDetail: () => void
}
export const WorkoutDetailModal: FC<IWorkoutDetailModal> = ({
	renderButton,
	classId,
	handleCloseDetail,
	date,
}) => {
	const { data: workout, isLoading } = useQuery({
		queryKey: ['workout', classId],
		queryFn: () => getClassById(classId),
	})
	const [activeTab, setActiveTab] = useState<
		'details' | 'composition' | 'photo'
	>('details')
	const tabs = {
		details: WorkoutDetailFeatures,
		composition: WorkoutSquad,
		photo: WorkoutPhoto,
	}
	const { LG_SCREEN } = useResize()
	const hasTouch = matchMedia('(hover: none)').matches
	useEffect(() => {
		document.body.style.overflow = 'hidden'

		return () => {
			document.body.style.overflow = ''
		}
	}, [LG_SCREEN])
	const ActiveTabComponent = tabs[activeTab]
	const [isCopiedUrl, setIsCopiedUrl] = useState(false)
	const dayOfTheWeek = dayjs(date).isoWeekday()
	const isOnLeftSide = dayOfTheWeek > 3
	useEffect(() => {
		const contentContainer = document.querySelector(
			'.workout-detail-modal__content'
		)
		if (contentContainer) {
			contentContainer.scrollTop = 0
		}
	}, [activeTab])

	const [touchPosition, setTouchPosition] = useState<number | null>(null)
	const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
		if (!hasTouch) return
		const touchDown = e.touches[0].clientY
		setTouchPosition(touchDown)
	}

	const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
		if (!hasTouch || touchPosition === null) return
		if (touchPosition > 250) return
		const currentPosition = e.touches[0].clientY
		const diff = currentPosition - touchPosition
		if (diff > 20) {
			handleCloseDetail()
		}
	}
	return (
		<section
			id="workout-detail-modal"
			onTouchStart={handleTouchStart}
			onTouchMove={handleTouchMove}
			className={clsx('workout-detail-modal', workout && 'loaded')}
			data-side={isOnLeftSide ? 'left' : 'right'}
		>
			{isLoading && LG_SCREEN && (
				<>
					<Skeleton width={270} height={30} />
					<Skeleton width={180} style={{ marginBlock: 10 }} />
					<Skeleton width={150} style={{ marginBottom: 10 }} />
					<div className="workout-detail-modal__switcher" style={{ gap: 0 }}>
						<Skeleton
							width={100}
							count={3}
							inline
							containerClassName="workout-detail-modal__switcher__tabs"
							style={{ marginBlock: '24px' }}
						/>
					</div>
					<div className="workout-detail-modal__content">
						<Skeleton width={70} />
						<Skeleton width={200} style={{ marginTop: 10 }} />
						<Skeleton width={70} style={{ marginTop: 16 }} />
						<Skeleton width={200} style={{ marginTop: 10 }} />
						<Skeleton width={70} style={{ marginTop: 16 }} />
						<Skeleton width={200} style={{ marginTop: 10 }} />
						<Skeleton width={70} style={{ marginTop: 16 }} />
						<Skeleton width={120} style={{ marginTop: 10 }} />
						<Skeleton width={120} style={{ marginTop: 10 }} />
						<Skeleton width={120} style={{ marginTop: 10 }} />
					</div>
					<div className="workout-detail-modal__footer">
						<Skeleton width={180} height={30} />
						<Skeleton width={30} height={30} />
					</div>
				</>
			)}
			{workout && (
				<div className="workout-detail-modal__wrapper">
					<div className="workout-detail-modal__header">
						<h3 className="workout-detail-modal__header__title">
							<span className="capitalize">
								{dayjs(workout.date).format('dddd, ')}
							</span>
							<span>{dayjs(workout.date).format('D MMMM')}</span>
						</h3>
						<LevelInfo
							className="workout-detail-modal__header__data"
							levelTitle={`${workout.level.title} уровень`}
							levelColor={workout.level.color}
							is_changed_level={workout.is_changed_level}
						/>
						<Interval
							start={workout.date}
							duration={workout.duration}
							className="workout-detail-modal__header__data"
						/>
					</div>
					<div className="workout-detail-modal__switcher">
						<div className="workout-detail-modal__switcher__tabs">
							<Tab
								onClick={(e) => {
									e.stopPropagation()
									setActiveTab('details')
								}}
								active={activeTab === 'details'}
							>
								Подробности
							</Tab>
							<Tab
								onClick={(e) => {
									e.stopPropagation()
									setActiveTab('photo')
								}}
								active={activeTab === 'photo'}
							>
								Фото
							</Tab>
							<Tab
								onClick={(e) => {
									e.stopPropagation()
									setActiveTab('composition')
								}}
								active={activeTab === 'composition'}
							>
								Состав
							</Tab>
						</div>
					</div>
					<div className="workout-detail-modal__content">
						<ActiveTabComponent workout={workout} />
					</div>
					<div className="workout-detail-modal__footer">
						<div className="workout-detail-modal__footer__wrapper">
							{renderButton()}
							<button
								onClick={() => {
									const url = window.location.href
									navigator.clipboard.writeText(url)
									setIsCopiedUrl(true)
								}}
								className={clsx(
									'workout-detail-modal__footer__share',
									isCopiedUrl && 'copied'
								)}
							>
								{isCopiedUrl ? <CheckIcon /> : <ShareIcon />}
							</button>
						</div>
					</div>
				</div>
			)}
		</section>
	)
}
