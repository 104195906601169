import { FC } from 'react'

import { ReactComponent as HighBowlSvg } from 'shared/assets/icons/highBowl.svg'

interface IHighBowlIcon {
	readonly className?: string
}

export const HighBowlIcon: FC<IHighBowlIcon> = ({ className }) => {
	return <HighBowlSvg className={className} />
}
