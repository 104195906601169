import clsx from 'clsx'
import { FC, useEffect, useRef } from 'react'
import { flushSync } from 'react-dom'

import { SunIcon } from 'shared/icons'
import { MoonIcon } from 'shared/icons/moonIcon'
import { Theme, useThemeContext } from 'shared/model'

import './toggleTheme.scss'

interface IToggleTheme {
	readonly className?: string
	readonly children?: React.ReactNode
}

export const ToggleTheme: FC<IToggleTheme> = ({ className, children }) => {
	const { theme, setTheme } = useThemeContext(({ theme, setTheme }) => ({
		theme,
		setTheme,
	}))
	const ref = useRef<HTMLButtonElement>(null)
	const isDarkMode = theme === Theme.DARK
	const toggleDarkMode = async () => {
		if (
			!ref.current ||
			!document.startViewTransition ||
			window.matchMedia('(prefers-reduced-motion: reduce)').matches
		) {
			document.documentElement.style.setProperty(
				'--primary-color',
				`${isDarkMode ? '#03749c' : '#337b3a'}`
			)
			setTheme(isDarkMode ? Theme.LIGHT : Theme.DARK)
			return
		}

		await document.startViewTransition(() => {
			flushSync(() => {
				setTheme(isDarkMode ? Theme.LIGHT : Theme.DARK)
			})
		}).ready

		const { top, left, width, height } = ref.current.getBoundingClientRect()
		const x = left + width / 2
		const y = top + height / 2
		const right = window.innerWidth - left
		const bottom = window.innerHeight - top
		const maxRadius = Math.hypot(Math.max(left, right), Math.max(top, bottom))

		const clipPath = [
			`circle(0px at ${x}px ${y}px)`,
			`circle(${maxRadius}px at ${x}px ${y}px)`,
		]

		document.documentElement.animate(
			{
				clipPath,
			},
			{
				duration: 1000,
				easing: 'ease-in-out',
				pseudoElement: '::view-transition-new(root)',
			}
		)
	}
	const moon = document.getElementById('moon')
	const sun = document.getElementById('sun')
	useEffect(() => {
		const handleAnimationEnd = (el: HTMLElement) => () => {
			el.classList.remove('out')
		}
		if (moon) {
			moon.addEventListener('animationend', handleAnimationEnd(moon))
		}
		if (sun) {
			sun.addEventListener('animationend', handleAnimationEnd(sun))
		}
		return () => {
			if (moon) {
				moon.removeEventListener('animationend', handleAnimationEnd(moon))
			}
			if (sun) {
				sun.removeEventListener('animationend', handleAnimationEnd(sun))
			}
		}
	}, [moon, sun])
	return (
		<button
			className={clsx('switch-theme', className)}
			aria-label="Переключить тему"
			onClick={toggleDarkMode}
			ref={ref}
		>
			<SunIcon
				id="sun"
				className={clsx('sun-switch', isDarkMode ? 'active' : 'out')}
			/>
			<MoonIcon
				id="moon"
				className={clsx('moon-switch', !isDarkMode ? 'active' : 'out')}
			/>
			{children}
		</button>
	)
}
