import { queryOptions } from '@tanstack/react-query'

import { getTypeClasses } from 'shared/api/class'
import { getAllGyms } from 'shared/api/gym/gym'
import { getPlayerLevels } from 'shared/api/level'
import { getTrainers } from 'shared/api/trainer'

export const trainersOptions = queryOptions({
	queryKey: ['trainers'],
	queryFn: () => getTrainers(),
	staleTime: 60000 * 30,
	select: ({ data }) => {
		const published = data.filter((item) => item.active === true)
		return published.sort((a, b) => (a.last_name > b.last_name ? 1 : -1))
	},
})

export const gymsOptions = queryOptions({
	queryKey: ['gyms'],
	queryFn: () => getAllGyms(),
	staleTime: 60000 * 30,
	select: ({ data }) => {
		const published = data.filter((item) => item.is_published === true)
		return published.sort((a, b) => (a.name > b.name ? 1 : -1))
	},
})

export const classTypesOptions = queryOptions({
	queryKey: ['class types'],
	queryFn: () => getTypeClasses(),
	staleTime: 60000 * 30,
	select: (data) => {
		const published = data.filter((item) => item.is_published === true)
		return published.sort()
	},
})
export const classLevelOptions = queryOptions({
	queryKey: ['player levels'],
	queryFn: () => getPlayerLevels(),
	select: ({ data }) => data.filter((item) => item.is_published === true),
	staleTime: 60000 * 30,
})
