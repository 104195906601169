import { InputMask } from '@react-input/mask'
import { FC } from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'

import { ProfileCard } from 'entities/profileCard'

import { ISettingFields } from 'shared/api/account'
import { Input } from 'shared/input'
import { Field } from 'shared/ui/field'

import './profileSettingsContacts.scss'

interface IProfileSettingsContacts {
	readonly control: Control<ISettingFields>
	readonly errors: FieldErrors<ISettingFields>
}

export const ProfileSettingsContacts: FC<IProfileSettingsContacts> = ({
	control,
	errors,
}) => {
	return (
		<article className="profile-settings-contacts">
			<ProfileCard>
				<h3 className="profile-settings-contacts__title">Контактные данные</h3>
				<Controller
					name="phone"
					control={control}
					rules={{ minLength: { value: 18, message: 'Неверный формат' } }}
					render={({ field }) => (
						<div className="main-info-settings__control">
							<label htmlFor="phone">
								{errors.phone ? (
									<span className="red-highlight">{errors.phone.message}</span>
								) : (
									<>
										Телефон <span className="green-highlight">*</span>
									</>
								)}
							</label>
							<InputMask
								component={Input}
								mask="+7 (___) ___-__-__"
								replacement={{ _: /\d/ }}
								showMask
								id="phone"
								placeholder="+7 (___) ___-__-__"
								required
								{...field}
							/>
						</div>
					)}
				/>
				<Field
					control={control}
					id="telegram"
					label="Телеграм"
					placeholder="@username"
					error={errors.telegram?.message}
				/>
			</ProfileCard>
		</article>
	)
}
