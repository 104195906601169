import clsx from 'clsx'
import { FC, RefObject } from 'react'

import { CloseIcon, FilterIcon } from 'shared/icons'
import { toastError } from 'shared/lib/handleError'
import { useResize } from 'shared/lib/useResize'
import { useScheduleStore } from 'shared/model'
import { IFilterType } from 'shared/types'
import { Button } from 'shared/ui/button'

import './filterManage.scss'

interface IFilterManage {}

export const FilterManage: FC<IFilterManage> = () => {
	const { filters, setFilters, setIsOpenFilters, isOpenFilters } =
		useScheduleStore(
			({ filters, setFilters, isOpenFilters, setIsOpenFilters }) => ({
				filters,
				setFilters,
				isOpenFilters,
				setIsOpenFilters,
			})
		)
	const countGymFilter = filters.gym.length
	const countTrainerFilter = filters.trainer.length
	const countClassLevelFilter = filters.classLevel.length
	const hasActiveFilter =
		countGymFilter + countTrainerFilter + countClassLevelFilter > 0
	const clearFilter = (
		filterName: 'classLevel' | 'trainer' | 'gym' | 'all'
	) => {
		let newFilters = {} as IFilterType
		switch (filterName) {
			case 'trainer':
				newFilters = {
					...filters,
					trainer: [],
				}
				break
			case 'gym':
				newFilters = {
					...filters,
					gym: [],
				}
				break
			case 'classLevel':
				newFilters = {
					...filters,
					classLevel: [],
				}
				break
			case 'all':
				newFilters = {
					...filters,
					trainer: [],
					gym: [],
					classLevel: [],
				}
				break
			default:
				toastError('Что-то пошло не так', 'Ошибка фильтрации')
				break
		}
		setFilters({
			...filters,
			...newFilters,
		})
	}

	return (
		<div className="filter-manage">
			<Button
				onClick={(e) => {
					e.stopPropagation()
					setIsOpenFilters(!isOpenFilters)
				}}
				className={clsx(
					'filter-manage__trigger-btn',
					isOpenFilters && 'active'
				)}
				theme="transparent-green"
			>
				<FilterIcon />
			</Button>
			{countGymFilter > 0 && (
				<Button
					className="filter-manage__btn scale-in"
					Icon={CloseIcon}
					onClick={() => clearFilter('gym')}
				>
					Зал
				</Button>
			)}
			{countTrainerFilter > 0 && (
				<Button
					className="filter-manage__btn scale-in"
					Icon={CloseIcon}
					onClick={() => clearFilter('trainer')}
				>
					Тренер
				</Button>
			)}
			{countClassLevelFilter > 0 && (
				<Button
					className="filter-manage__btn scale-in"
					Icon={CloseIcon}
					onClick={() => clearFilter('classLevel')}
				>
					Уровень
				</Button>
			)}
			{hasActiveFilter && (
				<Button
					className="filter-manage__btn scale-in"
					theme="red"
					Icon={CloseIcon}
					onClick={() => clearFilter('all')}
				>
					Очистить
				</Button>
			)}
		</div>
	)
}
