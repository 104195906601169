import clsx from 'clsx'
import { FC } from 'react'

import { StarIcon } from 'shared/icons'

import './rating.scss'

interface IRating {
	readonly readonly?: boolean
	readonly rate: number
	readonly setRate?: React.Dispatch<React.SetStateAction<number>>
}

export const Rating: FC<IRating> = ({ readonly, setRate, rate = 0 }) => {
	return (
		<div>
			<ul className="rating-container">
				{new Array(5).fill(0).map((_, idx) => (
					<li
						onClick={() => !readonly && setRate && setRate(idx + 1)}
						key={idx}
						className={clsx(
							'rating-container__item',
							readonly && 'readonly',
							idx < rate && 'filled'
						)}
					>
						<StarIcon className="rating-container__star" />
					</li>
				))}
			</ul>
		</div>
	)
}
