import { FC } from 'react'

import { ReactComponent as ShareSvg } from 'shared/assets/icons/share.svg'

interface IShareIcon {
	readonly className?: string
}

export const ShareIcon: FC<IShareIcon> = ({ className }) => {
	return <ShareSvg className={className} />
}
