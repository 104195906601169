import { FC } from 'react'

import { ReactComponent as SunSvg } from 'shared/assets/icons/sun.svg'

interface ISunIcon {
	readonly className?: string
	readonly id?: string
}

export const SunIcon: FC<ISunIcon> = ({ className, id }) => {
	return <SunSvg id={id} className={className} />
}
