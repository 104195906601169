import { FC } from 'react'

import { ReactComponent as DropSvg } from 'shared/assets/icons/drop.svg'

interface IDropIcon {
	readonly className?: string
}

export const DropIcon: FC<IDropIcon> = ({ className }) => {
	return <DropSvg className={className} />
}
