import { FC } from 'react'

import { ReactComponent as BellSvg } from 'shared/assets/icons/bell.svg'

interface IBellIcon {
	readonly className?: string
}

export const BellIcon: FC<IBellIcon> = ({ className }) => {
	return <BellSvg className={className} />
}
