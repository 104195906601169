import clsx from 'clsx'
import { FC } from 'react'

import { MetroIcon } from 'shared/icons'

import './metro.scss'

interface IClassGym {
	title: string
	className?: string
	white?: boolean
}

export const Metro: FC<IClassGym> = ({ title, className, white }) => {
	return (
		<div className={clsx('metro', className)}>
			<MetroIcon white={white} />
			<span className="ellipsis">{title}</span>
		</div>
	)
}
