import { FC, useCallback, useEffect, useState } from 'react'

import DefImg from 'shared/assets/default.jpg'
import { Arrow } from 'shared/icons'

import './carousel.scss'

interface ICarousel {
	readonly size: number
	readonly unit?: 'px' | '%'
	readonly images?: string[]
	readonly gap?: number
	readonly slidesPerView?: number
}

export const Carousel: FC<ICarousel> = ({
	size,
	unit = 'px',
	images = [DefImg, DefImg, DefImg, DefImg],
	gap = 8,
	slidesPerView = 2,
}) => {
	const el = document.querySelector('.carousel__scrollable')
	const [showLeftArrow, setShowLeftArrow] = useState(false)
	const [showRightArrow, setShowRightArrow] = useState(true)
	const [activeSlide, setActiveSlide] = useState(0)
	const handleClickArrow = (direction: 1 | -1) => () => {
		const newActiveSlide = activeSlide + direction

		const slideEl = document.getElementById(`slide-${newActiveSlide}`)
		if (slideEl) {
			slideEl.scrollIntoView({
				block: 'start',
				inline: 'start',
				behavior: 'smooth',
			})
		}
		setActiveSlide(newActiveSlide)
	}

	const handleScroll = useCallback(
		(e: Event) => {
			const target = e.target as HTMLDivElement
			if (target.scrollLeft > activeSlide * (size + gap)) {
				const newActiveSlide = Math.round(target.scrollLeft / (size + gap))
				setActiveSlide(newActiveSlide)
			} else if (target.scrollLeft < activeSlide * (size + gap)) {
				const newActiveSlide = Math.round(target.scrollLeft / (size + gap))
				setActiveSlide(newActiveSlide)
			}

			if (target.scrollLeft === 0) {
				setShowLeftArrow(false)
			} else {
				setShowLeftArrow(true)
			}
			if (target.scrollLeft >= target.scrollWidth - target.clientWidth) {
				setShowRightArrow(false)
			} else {
				setShowRightArrow(true)
			}
		},
		[activeSlide, size, gap]
	)
	useEffect(() => {
		if (el) {
			el?.addEventListener('scroll', handleScroll)
		}
		return () => {
			el?.removeEventListener('scroll', handleScroll)
		}
	}, [el, handleScroll])
	return (
		<div style={{ height: `${size}${unit}` }} className="carousel">
			<button
				disabled={!showLeftArrow}
				className="carousel__control left"
				onClick={handleClickArrow(-1)}
			>
				<Arrow />
			</button>
			<button
				disabled={!showRightArrow}
				className="carousel__control"
				onClick={handleClickArrow(1)}
			>
				<Arrow />
			</button>
			<div
				className="carousel__scrollable"
				style={{ height: `${size}${unit}` }}
			>
				<ul
					className="carousel__content"
					role="presentation"
					style={{
						width: `${size * images.length + gap * (images.length - 1)}${unit}`,
						gap: `${gap}px`,
					}}
				>
					{new Array(6).fill(0).map((_, idx) => (
						<li key={idx} className="carousel__item" id={`slide-${idx}`}>
							<img
								width={size}
								src={`/photo${idx + 1}.webp`}
								alt="Фото зала"
								draggable={false}
							/>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}
