import { FC } from 'react'

import { ReactComponent as PersonSvg } from 'shared/assets/icons/person2.svg'

interface IPersonIcon {
	readonly className?: string
}

export const PersonIcon: FC<IPersonIcon> = ({ className }) => {
	return <PersonSvg className={className} />
}
