import clsx from 'clsx'
import { FC, useState } from 'react'

import defaultAvt from 'shared/assets/default-photo-avatar.webp'
import { Arrow, LevelIcon, MetroIcon } from 'shared/icons'
import { useResize } from 'shared/lib/useResize'
import { useScheduleStore } from 'shared/model'
import { IClassLevel, IGym, ITrainer } from 'shared/types'
import { Button } from 'shared/ui/button'
import { CloseButton } from 'shared/ui/closeButton'
import { ToggleCheckbox } from 'shared/ui/toggleCheckbox'

import './scheduleFilter.scss'

interface IScheduleFilter {
	readonly trainers: ITrainer[]
	readonly gyms: IGym[]
	readonly levels: IClassLevel[]
}

export const ScheduleFilter: FC<IScheduleFilter> = ({
	gyms,
	levels,
	trainers,
}) => {
	const { filters, setFilters, isOpenFilters, setIsOpenFilters, classes } =
		useScheduleStore(
			({ filters, setFilters, isOpenFilters, setIsOpenFilters, classes }) => ({
				filters,
				setFilters,
				isOpenFilters,
				setIsOpenFilters,
				classes,
			})
		)
	const { MD_SCREEN } = useResize()
	const handleFilter = (
		filter: 'classLevel' | 'trainer' | 'gym',
		id: number
	) => {
		if (filters[filter].includes(id)) {
			const newFilter = filters[filter].filter((item) => item !== id)
			setFilters({ ...filters, [filter]: newFilter })
		} else {
			const newFilter = [...filters[filter], id]
			setFilters({ ...filters, [filter]: newFilter })
		}
	}
	const [isOpenGymFilter, setIsOpenGymFilter] = useState(true)
	const [isOpenTrainerFilter, setIsOpenTrainerFilter] = useState(true)
	const [isOpenLevelFilter, setIsOpenLevelFilter] = useState(true)
	return (
		<aside
			className={clsx('schedule-filter', isOpenFilters && 'active')}
			id="schedule-filter"
		>
			<div className="schedule-filter__wrapper">
				<div className="schedule-filter__container">
					<button
						onClick={() => setIsOpenGymFilter(!isOpenGymFilter)}
						className="schedule-filter__container__header"
					>
						<h3 className="schedule-filter__container__title">Зал</h3>

						<Arrow
							className={clsx(
								'schedule-filter__container__arrow',
								isOpenGymFilter && 'active'
							)}
						/>
					</button>
					<div
						className={clsx(
							'schedule-filter__container__wrapper',
							!isOpenGymFilter && 'hide'
						)}
					>
						<ul className={clsx('schedule-filter__container__list')}>
							{gyms?.map((gym) => (
								<li key={gym.id}>
									<ToggleCheckbox
										accentColor={'var(--primary-green)'}
										Icon={MetroIcon}
										checked={filters.gym?.includes(gym.id)}
										onClick={() => {
											handleFilter('gym', gym.id)
										}}
									>
										{gym.name}
									</ToggleCheckbox>
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className="schedule-filter__container">
					<button
						onClick={() => setIsOpenTrainerFilter(!isOpenTrainerFilter)}
						className="schedule-filter__container__header"
					>
						<h3 className="schedule-filter__container__title">Тренер</h3>
						<Arrow
							className={clsx(
								'schedule-filter__container__arrow',
								isOpenTrainerFilter && 'active'
							)}
						/>
					</button>
					<div
						className={clsx(
							'schedule-filter__container__wrapper',
							!isOpenTrainerFilter && 'hide'
						)}
					>
						<ul className="schedule-filter__container__list">
							{trainers?.map((trainer) => (
								<li
									key={trainer.id}
									className="schedule-filter__container__list__item"
								>
									<ToggleCheckbox
										accentColor={'var(--primary-green)'}
										avatar={defaultAvt}
										checked={filters.trainer?.includes(trainer.id)}
										onClick={() => {
											handleFilter('trainer', trainer.id)
										}}
									>
										{trainer.last_name} {trainer.first_name}
									</ToggleCheckbox>
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className="schedule-filter__container">
					<button
						onClick={() => setIsOpenLevelFilter(!isOpenLevelFilter)}
						className="schedule-filter__container__header"
					>
						<h3 className="schedule-filter__container__title">Уровень</h3>
						<Arrow
							className={clsx(
								'schedule-filter__container__arrow',
								isOpenLevelFilter && 'active'
							)}
						/>
					</button>
					<div
						className={clsx(
							'schedule-filter__container__wrapper',
							!isOpenLevelFilter && 'hide'
						)}
					>
						<ul className="schedule-filter__container__list">
							{levels?.map((level) => (
								<li key={level.id}>
									<ToggleCheckbox
										accentColor={level.color}
										Icon={LevelIcon}
										checked={filters.classLevel?.includes(level.id)}
										onClick={() => {
											handleFilter('classLevel', level.id)
										}}
									>
										{level.title}
									</ToggleCheckbox>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
			{MD_SCREEN && (
				<div className="schedule-filter__footer">
					<Button
						disabled={classes.length === 0}
						onClick={() => setIsOpenFilters(false)}
						theme="green"
					>
						<span>Показать</span>
						<span className="schedule-filter__footer__count">
							{classes.length}
						</span>
					</Button>
					<Button
						onClick={() => setFilters({ trainer: [], gym: [], classLevel: [] })}
						theme="red"
					>
						Сбросить фильтр
					</Button>
				</div>
			)}
			{/* {MD_SCREEN && (
				<CloseButton
					className="schedule-filter__close"
					onClick={() => setIsOpenFilters(false)}
				/>
			)} */}
		</aside>
	)
}
