import { Link } from 'react-router-dom'

import { SOCIAL } from 'shared/constants'
import { BgBalls } from 'shared/ui/bgBalls'
import { Logo } from 'shared/ui/logo'

import './Footer.scss'

function Footer() {
	return (
		<footer className="footer">
			{/* <BgBalls /> */}
			<div className="footer__wrapper">
				<Logo />
				<div className="footer__wrapper__social">
					{SOCIAL.map(({ name, link, Icon }) => (
						<a
							key={name}
							href={link}
							aria-label={name}
							target="_blank"
							rel="noopener nofollow noreferrer"
						>
							<Icon />
						</a>
					))}
				</div>
				<Link className="footer__wrapper__policy" to="/page/policy">
					Политика конфиденциальности
				</Link>
			</div>
		</footer>
	)
}

export default Footer
