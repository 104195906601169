import { useEffect, useState } from 'react'

export const useResize = () => {
	const [width, setWidth] = useState(window.innerWidth)
	useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth)
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])
	const isMobileScreen = width <= 768
	const TABLET_W = width <= 1024 && width > 768
	const isTabletScreen = width <= 1024
	const LARGE_W = width >= 1024 && width < 1440
	const isLargeScreen = width >= 1024
	const isXLargeScreen = width > 1440
	const isMediumScreen = width <= 1250
	const SM_SCREEN = width <= 576
	const MD_SCREEN = width <= 768
	const LG_SCREEN = width <= 992
	const XL_SCREEN = width <= 1200
	const XXL_SCREEN = width <= 1400
	const XXXL_SCREEN = width <= 1650
	const BIG_SCREEN = width > 1650
	return {
		width,
		isMobileScreen,
		LARGE_W,
		isXLargeScreen,
		TABLET_W,
		isLargeScreen,
		isTabletScreen,
		isMediumScreen,
		SM_SCREEN,
		MD_SCREEN,
		LG_SCREEN,
		XL_SCREEN,
		XXL_SCREEN,
		XXXL_SCREEN,
		BIG_SCREEN,
	}
}
