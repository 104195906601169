import { api } from '../../constants/api';
// Components
import BirthDayMask from '../BirthDayMask';
import Info from '../IconComponents/Info';
import PhoneMask from '../PhoneMask';
// Files
import './RegistrationPage.scss';
import RulesBlock from './RulesBlock/RulesBlock';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'sonner';
import * as Yup from 'yup';

const RegistrationPage = () => {
  const navigate = useNavigate();

  const [showInfo, setShowInfo] = useState(false);
  const [showInfoTwo, setShowInfoTwo] = useState(false);
  const [error, setError] = useState({});

  // если человек зарегистрирован, вместо регистрации откроется главная страница
  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');

    if (accessToken) {
      navigate('/');
    }
  }, [navigate]);

  /*** Handlers ***/

  const handleSubmit = (values) => {
    values.phone = values.phone.replace(/[^+\d]/gm, '');
    api
      .post('sign-up/', values)
      .then(({ data }) => {
        console.log(data);
        // переход на страницу подтверждения по email или по телефону
        data.email ? navigate('/confirmation-email/') : navigate('/confirmation-phone/');
      })
      .catch(({ response }) => {
        if (response.status === 400) {
          setError(response.data);
        } else {
          toast.error(`Произошла ошибка: ${response.status} ${response.data.message}`);
        }
      });
  };

  // Валидация полей формы
  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required('Обязательное поле')
      .matches(/[а-яёА-ЯË]/, 'Используйте русский язык'),
    last_name: Yup.string()
      .required('Обязательное поле')
      .matches(/[а-яёА-ЯË]/, 'Используйте русский язык'),
    birthday: Yup.string().required('Обязательное поле').min(10, 'Неверный формат'),
    phone: Yup.string()
      .required('Обязательное поле')
      .transform((value) => {
        return value.replace(/[^+\d]/gm, '');
      })
      .min(12, 'Некорректный номер телефона'),
    email: Yup.string().email('Введите корректный email').required('Обязательное поле'),
    username: Yup.string().required('Обязательное поле'),
    password: Yup.string()
      .min(8, 'Пароль должен содержать 8+ символов')
      .matches(/([0-9]*)(?=.*[a-zA-Z])/, 'Пароль не может состоять только из цифр')
      .required('Обязательное поле'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password')], 'Пароли должны совпадать')
      .required('Обязательное поле'),
    sex: Yup.string().required('Обязательное поле'),
    agreed_to_terms: Yup.bool().oneOf([true], 'Необходимо согласиться с правилами'),
    personal_data: Yup.bool().oneOf([true], 'Необходимо согласиться с политикой конфиденциальности'),
  });

  const handleInfoClick = () => setShowInfo(!showInfo);
  const handleInfoClickTwo = () => setShowInfoTwo(!showInfoTwo);

  return (
    <div className="container">
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          first_name: '',
          last_name: '',
          birthday: '',
          phone: '',
          email: '',
          username: '',
          password: '',
          password_confirmation: '',
          sex: 'm',
          agreed_to_terms: true,
          personal_data: false,
        }}
        validationSchema={validationSchema}
      >
        {({ handleChange }) => {
          return (
            <Form className="form">
              <div className="form__block">
                <h1 className="form__title">Регистрация</h1>

                <div className="form__header">
                  <h2 className="form__subtitle">Личные данные</h2>
                  <div className="info" onClick={handleInfoClick}>
                    <div className="info__icon">
                      <Info />
                    </div>

                    {showInfo && (
                      <p className="info__text">
                        Укажите данные, чтобы в дальнейшем мы могли комфортно общаться и подбирать тренировки
                      </p>
                    )}
                  </div>
                </div>

                <div className="form__container">
                  <label className="form__item form__input-name">
                    <Field
                      type="text"
                      name="first_name"
                      placeholder="Имя *"
                      className="form__name"
                      autoComplete="off"
                    />
                    {error.first_name && <div className="error">{error.first_name}</div>}
                    <ErrorMessage name="first_name" component="div" className="error" />
                  </label>

                  <label className="form__item form__input-surname">
                    <Field
                      type="text"
                      name="last_name"
                      placeholder="Фамилия *"
                      className="form__surname"
                      autoComplete="off"
                    />
                    {error.last_name && <div className="error">{error.last_name}</div>}
                    <ErrorMessage name="last_name" component="div" className="error" />
                  </label>

                  <label className="form__item form__input-birthdate">
                    <BirthDayMask
                      type="text"
                      name="birthday"
                      placeholder="Дата рождения *"
                      className="form__birthdate"
                      handleChange={handleChange}
                      autoComplete="off"
                      onClick={() => {
                        setError((draft) => ({ ...draft, birthday: null }));
                      }}
                    />
                    {error.birthday && <div className="error">{error.birthday}</div>}
                    <ErrorMessage name="birthday" component="div" className="error" />
                  </label>

                  <label className="form__item form__input-phone">
                    <PhoneMask
                      id="phone"
                      type="text"
                      name="phone"
                      className="form__phone"
                      placeholder="Телефон *"
                      handleChange={handleChange}
                      autoComplete="off"
                    />
                    {error.phone && <div className="error">{error.phone}</div>}
                    <ErrorMessage name="phone" component="div" className="error" />
                  </label>

                  <label className="form__item form__input-email">
                    <Field
                      type="email"
                      name="email"
                      placeholder="E-mail *"
                      className="form__email"
                      autoComplete="off"
                    />
                    {error.email && <div className="error">{error.email}</div>}
                    <ErrorMessage name="email" component="div" className="error" />
                  </label>
                </div>
              </div>

              <div className="form__block form__block--radio">
                <p className="form__block-title">Пол *</p>

                <div className="form__radio radio">
                  <label className="form__accepted-content">
                    <Field className="radio__input form__accepted-input" type="radio" name="sex" value="m" />
                    <p className="radio__name form__accepted-text">Мужской</p>
                  </label>

                  <label className="form__accepted-content">
                    <Field className="radio__input form__accepted-input" type="radio" name="sex" value="f" />
                    <p className="radio__name form__accepted-text">Женский</p>
                  </label>
                </div>
              </div>

              <div className="form__block">
                <div className="form__header">
                  <h2 className="form__subtitle">Защита аккаунта</h2>

                  <div className="info" onClick={handleInfoClickTwo}>
                    <div className="info__icon">
                      <Info />
                    </div>

                    {showInfoTwo && (
                      <p className="info__text">
                        Укажите данные, чтобы в дальнейшем мы могли комфортно общаться и подбирать тренировки
                      </p>
                    )}
                  </div>
                </div>
                <div className="form__container">
                  <label className="form__item form__input-login">
                    <Field
                      type="text"
                      name="username"
                      placeholder="Логин *"
                      className="form__login"
                      autoComplete="off"
                    />
                    {error.username && <div className="error">{error.username}</div>}
                    <ErrorMessage name="username" component="div" className="error" />
                  </label>
                  <label className="form__item form__input-password">
                    <Field
                      type="password"
                      name="password"
                      placeholder="Пароль *"
                      autoComplete="off"
                      onClick={() => {
                        setError((draft) => ({ ...draft, password: null }));
                      }}
                    />
                    {error.password && <div className="error">{error.password}</div>}
                    <ErrorMessage name="password" component="div" className="error" />
                  </label>
                  <label className="form__item form__input-confirm">
                    <Field
                      type="password"
                      name="password_confirmation"
                      placeholder="Подтвердить пароль *"
                      autoComplete="off"
                    />
                    {error.password_confirmation && <div className="error">{error.password_confirmation}</div>}
                    <ErrorMessage name="password_confirmation" component="div" className="error" />
                  </label>
                  {/* <p className="form__info">Формы, обязательные к заполнению</p> */}
                </div>
              </div>
              {/* 
              <RulesBlock />

              <label className="form__accepted">
                <div className="form__accepted-content">
                  <Field name="agreed_to_terms" className="form__accepted-input" type="checkbox" />

                  <p className="form__accepted-text">Я ознакомился с правилами школы</p>
                </div>
                <ErrorMessage name="agreed_to_terms" component="div" className="error" />
              </label> */}

              {/*<div className="recapthca">*/}
              {/* <ReCAPTCHA
            sitekey="ВАШ_КЛЮЧ_RECAPTCHA"
            onChange={this.handleRecaptchaChange}
          /> */}
              {/*</div>*/}

              <div className="form__btn-block">
                <label className="form__consent">
                  <Field name="personal_data" className="form__consent-input" type="checkbox" />

                  <p className="form__consent-text">
                    Нажимая на кнопку "Зарегистрироваться", Вы даете согласие на{' '}
                    <Link to="https://volleybox.su/page/policy">обработку персональных данных</Link>
                  </p>
                </label>
                {error.personal_data && <div className="error">{error.personal_data}</div>}
                <ErrorMessage name="personal_data" component="div" className="error" />

                <button className="form__btn" type="submit">
                  Зарегистрироваться
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default RegistrationPage;
