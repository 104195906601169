import { FC } from 'react'

import { ReactComponent as ClockSvg } from 'shared/assets/icons/clock.svg'

interface IClockIcon {
	readonly className?: string
}

export const ClockIcon: FC<IClockIcon> = ({ className }) => {
	return <ClockSvg className={className} />
}
