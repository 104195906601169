import clsx from 'clsx'
import { FC } from 'react'

import { ReactComponent as MetroSvg } from 'shared/assets/icons/metro.svg'

interface IMetroIcon {
	className?: string
	white?: boolean
}

export const MetroIcon: FC<IMetroIcon> = ({ className, white }) => {
	return (
		<MetroSvg
			className={clsx(
				'metro-icon-size',
				className,
				white && 'metro-icon__white'
			)}
		/>
	)
}
