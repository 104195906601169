import clsx from 'clsx'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { CalendarIcon, GreenArrow, StarIcon } from 'shared/icons'
import { formatDate } from 'shared/lib/utils'
import { IconTitle } from 'shared/ui/iconTitle'
import { Interval } from 'shared/ui/interval'
import { Metro } from 'shared/ui/metro'

import './profileHistoryItem.scss'

interface IProfileHistoryItem {
	readonly type: 'history' | 'future'
	readonly className?: string
	readonly date: string
	readonly metro: string
	readonly duration: number
	readonly id: number
	readonly handleRate?: () => void
}

export const ProfileHistoryItem: FC<IProfileHistoryItem> = ({
	type,
	className,
	date,
	metro,
	duration,
	id,
	handleRate,
}) => {
	return (
		<li className={clsx('profile-history-item', className)}>
			<IconTitle
				Icon={CalendarIcon}
				className="profile-history-item__date"
				title={formatDate(date, 'full')}
			/>
			<Metro title={metro} white className="profile-history-item__metro" />
			<Interval
				className="profile-history-item__time"
				start={date}
				duration={duration}
			/>
			{type === 'future' ? (
				<Link
					target="_blank"
					to={`/workout/${id}`}
					className="profile-history-item__link"
				>
					К тренировке
					<GreenArrow />
				</Link>
			) : (
				<div onClick={handleRate} className="profile-history-item__rate">
					<span>Оценить</span>
					<div className="profile-history-item__rate__star">
						<StarIcon />
					</div>
				</div>
			)}
		</li>
	)
}
