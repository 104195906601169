import { TelegramIcon, VkIcon, YoutubeIcon } from 'shared/icons'

export const SOCIAL = [
	{
		name: 'telegram',
		link: 'https://t.me/volleybox_official',
		Icon: TelegramIcon,
	},
	{
		name: 'vk',
		link: 'https://vk.com/vkoxygen',
		Icon: VkIcon,
	},
	{
		name: 'youtube',
		link: 'https://www.youtube.com/channel/UCXPcorogcpC0DSxx8vLYl3A',
		Icon: YoutubeIcon,
	},
]
